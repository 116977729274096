import React from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

function Box({ data = 1, children }) {
  let arr = [];
  for (let index = 1; index <= data; index++) {
    arr.push({ count: index });
  }
  return (
    <>
      <div className="row">
        {arr.map((card, ind) => (
          <div className="col-xl-3 col-md-6 mb-4" key={ind}>
            <div className={`card border-left- shadow h-100 py-2 `}>
              <div className="card-body mt-2">{children}</div>
            </div>
          </div>
        ))}
      </div>
    </>
  );
}

function SkeletonLodingWithCards({ count = 1 }) {
  return (
    <>
      <Box data={count}>
        <Skeleton height={40} />
      </Box>
      <div className="mr-4">
        <Skeleton height={35} count={10} style={{ margin: "2%" }} />
      </div>
    </>
  );
}

export default SkeletonLodingWithCards;
