import { WhatsappType } from "./Whatsapp.type";

const initialState = {
  whatsappMsgList: {},
};

function WhatsappReducer(state = initialState, action) {
  switch (action.type) {
    case WhatsappType.GET_WHATSAPP_DATA: {
      return { ...state, whatsappMsgList: action.payload };
    }

    default:
      return state;
  }
}

export default WhatsappReducer;
