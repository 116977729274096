import { SmsType } from "./Sms.type";

const initialState = {
  smsMsgList: {},
};

function SmsReducer(state = initialState, action) {
  switch (action.type) {
    case SmsType.GET_SMS_DATA: {
      return { ...state, smsMsgList: action.payload };
    }

    default:
      return state;
  }
}

export default SmsReducer;
