import React, { useEffect, useState } from "react";
import CommonTable from "../CommonComponents/CommonTable";
import { useDispatch } from "react-redux";
import {
  getCallGraphAction,
  getCallsListAction,
  getCallsListGatewayAction,
  getCallsListWithAccountPlusGatewaysAction,
  getQueueListAction,
  increaseQueueListAction,
} from "./Store/admin.action";
import CommonModal from "../CommonComponents/CommonModal";
import "../Dashboard/Onboarding.css";
import { useLocation } from "react-router-dom";
import { Scrollbars } from "react-custom-scrollbars";
import { LoadingDotsSpin, NoDataFound, renderThumb } from "../CommonComponents/Common";
import moment from "moment";
import TopCard from "../../../common/components/TopCard";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ChartOptions
} from 'chart.js';
import { Line } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);
interface CallGraphData {
  time: string;
  dialed: number;
  connected: number;
  error: number;
  failed: number;
  transferred: number;
  voicemail: number;
}
function TotalCalls() {
  const location = useLocation();
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [data1, setData1] = useState([]);
  const [data2, setData2] = useState([]);
  const [date, setDate] = useState("");
  const [spin, setSpin] = useState(false);
  const [graphData, setGraphData] = useState<CallGraphData[]>([])
  const [total, setTotal] = useState({ dialed: 0, connected: 0, error: 0, voicemail: 0 })
  const init = async () => {
    setSpin(true)
    let res: any = await dispatch(getCallsListAction(date));
    let graphRes: any = await dispatch(getCallGraphAction(date))
    setData(res?.data);
    setGraphData(graphRes?.data)
    setSpin(false)
    let res1: any = await dispatch(getCallsListGatewayAction(date));
    setData1(res1.data);
    let res2: any = await dispatch(
      getCallsListWithAccountPlusGatewaysAction(date)
    );
    setData2(res2.data);
  };

  useEffect(() => {
    const init = async () => {
      setSpin(true)
      const previousDay = moment().subtract(1, 'days');
      const formattedDate = previousDay.format('D-M-YYYY');
      let res1: any = await dispatch(getCallsListAction(formattedDate));
      setData(res1.data);
      setSpin(false)
      let res2: any = await dispatch(getCallsListGatewayAction(formattedDate));
      setData1(res2.data);
      let res3: any = await dispatch(
        getCallsListWithAccountPlusGatewaysAction(formattedDate)
      );
      setData2(res3.data);
      setDate(formattedDate)
    }
    init()
  }, [])
  const calculateConnectRatio = (connected, dialed) => {
    if (dialed === 0) return 0;
    return (connected / dialed) * 100;
  };
  const labels = graphData?.map((item) => item?.time);
  const callsDialed = graphData?.map((item) => item?.dialed);
  const callsConnected = graphData?.map((item) => item?.connected);
  const connectRatioData = graphData?.map(item =>
    calculateConnectRatio(item?.connected, item?.dialed)
  );
  const options: ChartOptions<'line'> = {
    responsive: true,
    interaction: {
      mode: 'index',
      intersect: false,
    },
    scales: {
      y: {
        type: 'linear',
        position: 'left',
        title: {
          display: true,
          text: 'Call Count',
        },
        min: 0,
        grid: {
          drawOnChartArea: true,
        },
      },
      percentage: {
        type: 'linear',
        position: 'right',
        title: {
          display: true,
          text: 'Connect Ratio (%)',
        },
        min: 0,
        max: 100,
        grid: {
          drawOnChartArea: false,
        },
      },
    },
    plugins: {
      legend: {
        position: 'top',
      },
      tooltip: {
        enabled: true,
      },
    },
  };

  const chartData = {
    labels,
    datasets: [
      {
        label: 'Outbound Attempted',
        data: callsDialed,
        borderColor: '#EC8C36',
        backgroundColor: '#EC8C36',
        borderWidth: 3,
        tension: 0,
        pointRadius: 2,
        pointBackgroundColor: '#EC8C36',
        yAxisID: 'y',
        fill: false
      },
      {
        label: 'Outbound Connected',
        data: callsConnected,
        borderColor: '#26DE5B',
        backgroundColor: '#26DE5B',
        borderWidth: 3,
        tension: 0,
        pointRadius: 2,
        pointBackgroundColor: '#26DE5B',
        yAxisID: 'y',
        fill: false
      },
      {
        label: 'Connect Ratio',
        data: connectRatioData,
        borderColor: '#C800FF',
        backgroundColor: '#C800FF',
        borderWidth: 3,
        tension: 0,
        pointRadius: 2,
        pointBackgroundColor: '#C800FF',
        yAxisID: 'percentage',
        fill: false
      },
    ],
  };
  useEffect(() => {
    const totals = data.reduce(
      (acc, que: any) => {
        acc.dialed += que?.account_data?.dialed || 0;
        acc.connected += que?.account_data?.connected || 0;
        acc.error += que?.account_data?.error || 0;
        acc.voicemail += que?.account_data?.voicemail || 0;

        return acc;
      },
      { dialed: 0, connected: 0, error: 0, voicemail: 0 }
    );
    setTotal(totals)
  }, [data])





  return (
    <div>
      <div className="row">
        <TopCard
          title="Total Calls"
          text={`${total?.dialed || 0}`}
          icon="box"
          class="primary"
        />
        <TopCard
          title="Total Connected"
          text={`${total?.connected || 0}`}
          icon="warehouse"
          class="danger"
        />
        <TopCard
          title="Total Failed or Errors"
          text={`${total?.error || 0}`}
          icon="phone-volume"
          class="success"
        />
        <TopCard
          title="Total Voicemail"
          text={`${total?.voicemail || 0}`}
          icon="phone-volume"
          class="success"
        />
      </div>
      <strong>Date format example : 9-9-2024  or 14-10-2024 (d-m-yyyy)</strong>

      <div className="card-header d-flex">
        <input
          type="text"
          className="form-control w-20"
          onChange={(e) => {
            e.persist();
            setDate(e.target.value);
          }}
          value={date}
        />
        <button className="custom-button-icon" onClick={init}>
          Fetch
        </button>

      </div>

      {spin ? <LoadingDotsSpin /> : <>

        <div className="card-body">
          <div style={{ marginTop: "23px", marginBottom: "43px" }}>
            <span style={{ color: "grey", fontSize: "13px", fontWeight: "" }}>CALL STATISTICS</span>
            <h3>Call Distribution</h3>
            <div className="w-full h-[400px]">
              <Line data={chartData} options={options} />
            </div>
          </div>
        </div>


        <div className="card">
          <strong className="ml-2 mt-2">Account Wise Calls Data</strong>

          <div className="card-body">
            <Scrollbars
              style={{ height: "420px", color: "white" }}
              renderThumbVertical={renderThumb}
            >
              <CommonTable
                body={data?.length > 0 ? data.map((que: any, idx) => (
                  <tr key={`${idx}comm`}>
                    <td>{que?.user_email}</td>
                    <td>{que?.account_name}</td>
                    <td>{que?.account_data?.dialed}</td>
                    <td>{que?.account_data?.connected}</td>
                    <td>{que?.account_data?.error}</td>
                    <td>{que?.account_data?.misc}</td>
                    <td>{que?.account_data?.voicemail}</td>
                    <td>{que?.account_data?.duration}</td>
                    <td>{que?.account_data?.price}</td>
                    <td>{que?.white_label_super_admin}</td>
                    <td>{que?.sid_data?.pikup_rate}</td>
                    <td>{que?.sid_data?.average_call_duration}</td>
                  </tr>
                )) : NoDataFound(12)}
                headers={[
                  "Account Email",
                  "Account Name",
                  "Total Dialed",
                  "Total Connected",
                  "Total Errors",
                  "Misc",
                  " Total Voicemail",
                  "Duration",
                  "Pricing",
                  "Whitelabel",
                  "Pickup Rate",
                  "Average Call Duration",
                ]}
              />
            </Scrollbars>
          </div>
        </div>
        <div className="card">
          <strong className="ml-2 mt-2"> Gateway Wise Calls Data</strong>


          <div className="card-body">
            <Scrollbars
              style={{ height: "420px", color: "white" }}
              renderThumbVertical={renderThumb}
            >
              <CommonTable
                body={data1?.length > 0 ? data1.map((que: any, idx) => (
                  <tr key={`${idx}comm`}>
                    <td>{que?.user_email}</td>
                    <td>{que?.account_name}</td>
                    <td>{que?.sid}</td>
                    <td>{que?.sid_data?.dialed}</td>
                    <td>{que?.sid_data?.connected}</td>
                    <td>{que?.sid_data?.error}</td>
                    <td>{que?.sid_data?.misc}</td>
                    <td>{que?.sid_data?.voicemail}</td>
                    <td>{que?.sid_data?.duration}</td>
                    <td>{que?.sid_data?.price}</td>
                    <td>{que?.sid_data?.pikup_rate}</td>
                    <td>{que?.sid_data?.average_call_duration}</td>
                  </tr>
                )) : NoDataFound(12)}
                headers={[
                  "Gateway Owner Email",
                  "Account Name",
                  "Account Sid",
                  "Total Dialed",
                  "Total Connected",
                  "Total Errors",
                  "Misc",
                  " Total Voicemail",
                  "Duration",
                  "Pricing",
                  "Pickup Rate",
                  "Average Call Duration",
                ]}
              />
            </Scrollbars>
          </div>
        </div>

        <div className="card">
          <strong className="ml-2 mt-2">
            {" "}
            Account + Gateway Wise Calls Data
          </strong>


          <div className="card-body">
            <Scrollbars
              style={{ height: "420px", color: "white" }}
              renderThumbVertical={renderThumb}
            >
              <CommonTable
                body={data2?.length > 0 ? data2.map((que: any, idx) => (
                  <tr key={`${idx}comm`}>
                    <td>{que?.user_email}</td>
                    <td>{que?.account_name}</td>
                    <td>{que?.sid}</td>
                    <td>{que?.data?.dialed}</td>
                    <td>{que?.data?.connected}</td>
                    <td>{que?.data?.error}</td>
                    <td>{que?.data?.misc}</td>
                    <td>{que?.data?.voicemail}</td>
                    <td>{que?.data?.duration}</td>
                    <td>{que?.data?.price}</td>
                    <td>{que?.data?.pikup_rate}</td>
                    <td>{que?.data?.average_call_duration}</td>
                  </tr>
                )) : NoDataFound(12)}
                headers={[
                  "Account Email",
                  "Account Name",
                  "Account Sid",
                  "Total Dialed",
                  "Total Connected",
                  "Total Errors",
                  "Misc",
                  " Total Voicemail",
                  "Duration",
                  "Pricing",
                  "Pickup Rate",
                  "Average Call Duration",
                ]}
              />
            </Scrollbars>
          </div>
        </div>
      </>}
    </div>
  );
}

export default TotalCalls;
