import {
  swalErrorMessage,
  swalSuccessMessage,
} from "../../../../Constant/swalMessage";
import {
  creditAccountService,
  deleteAccountService,
  gatewayQueueService,
  getAllUserService,
  getAllUserUsageService,
  getApiKeysService,
  getCallGraphService,
  getCallListGatewayService,
  getCallListService,
  getCallsListWithAccountPlusGatewaysService,
  getCreditAccountService,
  getGatewayListService,
  getQueueListService,
  getRechargeHistoryService,
  increaseQueueListService,
  pauseAccountService,
  rechargeUserService,
  saveMasterPasswordService,
  setPricingService,
} from "../../services/admin.services";
import { adminType } from "./admin.type";
export const getAllUsersAction = () => async (dispatch) => {
  let res = await getAllUserService();
  if (res) {
    await dispatch({
      type: adminType.GET_USERS_LIST,
      payload: res?.data,
    });
  }
};

export const rechargeUserAction = (obj) => async () => {
  let res = await rechargeUserService(obj);
  if (res.status === 200) {
    swalSuccessMessage(res.msg);
  } else {
    swalErrorMessage(res.errMsg);
  }
};

export const setPricingAction = (obj) => async () => {
  let res = await setPricingService(obj);
  if (res.status === 200) {
    swalSuccessMessage(res.msg);
  } else {
    swalErrorMessage(res.errMsg);
  }
};

export const getApiKeysAction = (obj) => async () => {
  let res = await getApiKeysService(obj);
  if (res.status === 200) {
    return res;
  } else {
    swalErrorMessage(res.errMsg);
  }
};

export const saveMasterPasswordAction = (obj) => async () => {
  let res = await saveMasterPasswordService(obj);
  if (res.status === 200) {
    swalSuccessMessage("Created Successfully");
    return res;
  } else {
    swalErrorMessage(res.errMsg);
  }
};

export const getAllUsersUsageAction = (days) => async (dispatch) => {
  let res = await getAllUserUsageService(days);
  if (res.status === 200) {
    await dispatch({
      type: adminType.GET_USERS_USAGE_LIST,
      payload: res?.data,
    });
  }
};

export const getRechargeHistoryAction = (id, obj) => async (dispatch) => {
  let res = await getRechargeHistoryService(id, obj);
  if (res.status === 200) {
    await dispatch({
      type: adminType.GET_RECHARGE_HISTORY,
      payload: {
        data: res?.data.balanceHistory || [],
        searchText: obj?.searchText,
        isScrolled: obj?.isScrolled,
        totalRechargeCount: res?.data?.count[0]?.count,
      },
    });
  } else {
    swalErrorMessage(res.errMsg);
  }
};

export const getSubAccountHistoryAction = (id, obj) => async (dispatch) => {
  let res = await getRechargeHistoryService(id, obj);
  if (res.status === 200) {
    await dispatch({
      type: adminType.GET_SUB_ACCOUNT_RECHARGE_HISTORY,
      payload: {
        data: res?.data.balanceHistory || [],
        searchText: obj?.searchText,
        isScrolled: obj?.isScrolled,
        totalRechargeCount: res?.data?.count[0]?.count,
      },
    });
  } else {
    swalErrorMessage(res.errMsg);
  }
};
export const getSpentHistoryAction = (id, obj) => async (dispatch) => {
  let res = await getRechargeHistoryService(id, obj);
  if (res.status === 200) {
    await dispatch({
      type: adminType.GET_SPENT_HISTORY,
      payload: {
        data: res?.data?.balanceHistory || [],
        searchText: obj?.searchText,
        isScrolled: obj?.isScrolled,
        totalSpentCount: res?.data?.count[0]?.count,
      },
    });
  } else {
    swalErrorMessage(res.errMsg);
  }
};

export const pauseAccountAction = (obj, id) => async () => {
  let res = await pauseAccountService(obj, id);
  if (res.status === 200) {
    swalSuccessMessage("Account Updated");
    return res;
  } else {
    swalErrorMessage(res.errMsg);
  }
};

export const creditAccountAction = (obj) => async () => {
  let res = await creditAccountService(obj);
  if (res.status === 200) {
    swalSuccessMessage("Account Credit Successfully");
    return res;
  } else {
    swalErrorMessage(res.errMsg);
  }
};

export const getCreditAccountAction = (obj) => async () => {
  let res = await getCreditAccountService(obj);
  if (res.status === 200) {
    return res.data;
  } else {
    swalErrorMessage(res.errMsg);
  }
};

export const deleteAccountAction = (obj) => async () => {
  let res = await deleteAccountService(obj);
  if (res.status === 200) {
    swalSuccessMessage(res?.msg);
    return res;
  } else {
    swalErrorMessage(res.errMsg);
  }
};

export const getQueueListAction = (obj) => async () => {
  let res = await getQueueListService(obj);
  return res;
};

export const increaseQueueListAction = (obj, id) => async () => {
  let res = await increaseQueueListService(obj, id);
  return res;
};

export const getCallsListAction = (obj) => async () => {
  let res = await getCallListService(obj);
  return res;
};

export const getCallsListGatewayAction = (obj) => async () => {
  let res = await getCallListGatewayService(obj);
  return res;
};

export const getCallsListWithAccountPlusGatewaysAction = (obj) => async () => {
  let res = await getCallsListWithAccountPlusGatewaysService(obj);
  return res;
};
export const getGatewayListAction = (obj) => async () => {
  let res = await getGatewayListService(obj);
  return res;
};

export const gatewayQueueAction = (action, gateway) => async () => {
  let res = await gatewayQueueService(action, gateway);
  return res;
};

export const getCallGraphAction = (date) => async () => {
  let res = await getCallGraphService(date)
  return res
}
