export const AccountsType = {
  GET_ACCOUNT_DETAILS: "GET_ACCOUNT_DETAILS",
  SELECT_ACCOUNT: "SELECT_ACCOUNT",
  CHANGE_MENU: "CHANGE_MENU",
  GET_CONSTANT_DATA: "GET_CONSTANT_DATA",
  GET_DASHBOARD_DATA: "GET_DASHBOARD_DATA",
  GET_CHART_DATA: "GET_CHART_DATA",
  CLEAR_ACCOUNT_REDUCER: "CLEAR_ACCOUNT_REDUCER",
  GET_WHITELABEL_DETAILS: "GET_WHITELABEL_DETAILS",
  ACCOUNT_ROLES: "ACCOUNT_ROLES",
  ADD_NOTIFICATION: "ADD_NOTIFICATION",
  REMOVE_NOTIFICATION: "REMOVE_NOTIFICATION",
};
