import { cloneDeep, concat, get, isEmpty } from "../../CommonComponents/Common";
import { accessType } from "./access.type";

const initialState = {
  usersList: [],
  accountHistory: [],
  accountHistoryCount: 0,
  voicesList: [],
};

function AccessReducer(state = initialState, action) {
  switch (action.type) {
    case accessType.GET_INVITE_USERS_LIST: {
      return { ...state, usersList: action.payload };
    }
    case accessType.ACCOUNT_VOICES_LIST: {
      return { ...state, voicesList: action.payload };
    }
    case accessType.ACCOUNT_HISTORY_LIST: {
      const updatedArry = cloneDeep(
        setArrayByCase(state.accountHistory, action.payload)
      );

      return {
        ...state,
        accountHistory: updatedArry,
        accountHistoryCount: action.payload.totalCount,
      };
    }
    default:
      return state;
  }
}

export default AccessReducer;

const setArrayByCase = (array, payload) => {
  if (!isEmpty(get(payload, "searchText")) && get(payload, "isScrolled")) {
    array = [...array, ...payload.data];
    return array;
  } else if (!isEmpty(get(payload, "searchText"))) {
    array = payload.data;
    return array;
  } else if (get(payload, "isScrolled")) {
    array = concat(array, payload.data);
    return array;
  }
  return payload.data;
};
