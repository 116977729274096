import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { verifyEmailAction } from "../Actions/CommonActions";
import { LoadingDots, isVodexLoggedin } from "../CommonComponents/Common";
import { acceptInviteAction, checkUserAction } from "./Store/access.action";
function InviteAccountVerfication() {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const locSearch = new URLSearchParams(location.search);
  let token = locSearch.get("token");
  let email = locSearch.get("email");

  const [state, setState] = useState({
    password: "",
    confirmPassword: "",
    show: false,
  });
  const { password, confirmPassword, show } = state;
  const [errors, setErrors] = useState(false);

  const [msg, setMsg] = useState("");
  const [spin, setSpin] = useState(true);
  useEffect(() => {
    const init = async () => {
      setSpin(true);

      let res: any = await dispatch(checkUserAction({ email }));
      if (res.status) {
        let res: any = await dispatch(
          acceptInviteAction({ email, token, password })
        );
        setState((prev) => ({
          ...prev,
          show: false,
        }));
      } else {
        setState((prev) => ({
          ...prev,
          show: true,
        }));
      }

      setSpin(false);
    };
    init();
  }, []);
  const handleChange = (name, value) => {
    setState((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  const verifyInvite = async (e) => {
    setSpin(true);
    e.preventDefault();

    if (
      password.length >= 8 &&
      /[A-Z]/.test(password) &&
      /[a-z]/.test(password) &&
      /\d/.test(password) &&
      /[!@#$%^&*()_+[\]{}|\\:;'"<>,.?/~`]/.test(password)
    ) {
      if (password === confirmPassword) {
        let res: any = await dispatch(
          acceptInviteAction({ email, token, password })
        );
        if (res) {
          setState((prev) => ({
            ...prev,
            show: false,
          }));
        }
      } else {
        setMsg(`Password doesn't match `);
        setTimeout(() => {
          setMsg("");
        }, 5000);
      }
    } else {
      setTimeout(() => {
        setErrors(false);
      }, 5000);
      setErrors(true);
    }
    setSpin(false);
  };
  return (
    <div className="limiter">
      <div
        className={`container-login100  ${
          !isVodexLoggedin() && "whitelabel-background"
        }`}
      >
        <div className="wrap-login100 p-l-55 p-r-55 p-t-65 p-b-54">
          <form className="login100-form validate-form" onSubmit={verifyInvite}>
            {show && (
              <>
                <div
                  className="wrap-input100 validate-input m-b-23"
                  data-validate="Password is required"
                >
                  <span className="label-input100">Password</span>
                  <input
                    className="input100"
                    type="password"
                    name="password"
                    placeholder="Type your password"
                    required={true}
                    value={password}
                    onChange={(e) => handleChange("password", e.target.value)}
                  />
                  <span
                    className="focus-input100"
                    data-symbol="&#xf190;"
                  ></span>
                </div>

                <div
                  className="wrap-input100 validate-input m-b-23"
                  data-validate="Password is required"
                >
                  <span className="label-input100">Confirm Password</span>
                  <input
                    className="input100"
                    type="password"
                    name="cpassword"
                    placeholder="Type your password"
                    required={true}
                    value={confirmPassword}
                    onChange={(e) =>
                      handleChange("confirmPassword", e.target.value)
                    }
                  />
                  <span
                    className="focus-input100"
                    data-symbol="&#xf190;"
                  ></span>
                </div>
              </>
            )}

            {msg && <p style={{ color: "red" }}>{msg}</p>}
            {errors && (
              <p style={{ color: "red" }}>
                Password must be at least 8 characters must contain an
                uppercase, lowercase,numbers and special characters
              </p>
            )}
            <div className="container-login100-form-btn">
              {/* <img src={img} height={"120px"} width={"120px"} className="mb-3" /> */}

              <div className="wrap-login100-form-btn">
                <div className="login100-form-bgbtn"></div>
                {show && (
                  <button
                    className="login100-form-btn"
                    type="submit"
                    disabled={spin}
                  >
                    {spin ? <LoadingDots /> : `Create Password`}
                  </button>
                )}
                {!show && (
                  <button
                    className="login100-form-btn"
                    type="submit"
                    onClick={() => {
                      history.push("/");
                    }}
                    disabled={spin}
                  >
                    {spin ? <LoadingDots /> : `Login Now`}
                  </button>
                )}
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default InviteAccountVerfication;
