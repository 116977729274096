import React, { useState } from "react";
import ModalVideo from "react-modal-video";
import "./Common.css";
import CommonModal from "./CommonModal";
import img from "./VoizerThumbnail.jpeg";

const VideoComponentCustom = () => {
  const [isOpen, setOpen] = useState(false);

  return (
    <>
      {" "}
      <div className="floating-widget1">
        {" "}
        <div onClick={() => setOpen(!isOpen)}>
          <div className="thumbnail-component">
            {isOpen ? (
              <i className="fas fa-times-circle"></i>
            ) : (
              <img
                height={"70px"}
                width={"100px"}
                src={img}
                alt="Video Thumbnail"
              />
            )}
          </div>
        </div>
      </div>
      <CommonModal
        show={isOpen}
        body={
          <>
            <i
              className="fas fa-times-circle m-1 float-right cursor-pointer"
              onClick={() => setOpen(!isOpen)}
            ></i>

            <ModalVideo
              url="https://www.loom.com/embed/6ef24a54ef79430390e1609d421d8aa3?sid=b5705621-a108-42fc-9f3d-adbcfe6e2a8d"
              channel="custom"
              isOpen={isOpen}
              // youtube={{ mute: 0, autoplay: 0 }}
              // videoId={videoId}
              onClose={() => setOpen(false)}
              ratio="16:12"
            />
          </>
        }
      />
    </>
  );
};

export default VideoComponentCustom;
