import { RecordingType } from "./Recording.type";

const initialState = {
  recordings: {},
};

function RecordingReducer(state = initialState, action) {
  switch (action.type) {
    case RecordingType.GET_RECORDING_DATA: {
      return { ...state, recordings: action.payload };
    }
    case RecordingType.CLEAR_RECORDING_DATA: {
      return { ...initialState };
    }

    default:
      return state;
  }
}

export default RecordingReducer;
