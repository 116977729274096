import { AgentType } from "./Agent.type";

const initialState = {
  agents: [],
  editAgentData: {},
  allAgents: [],
};

function AgentReducer(state = initialState, action) {
  switch (action.type) {
    case AgentType.GET_AGENT_DATA: {
      return {
        ...state,
        agents: action.payload.data,
      };
    }
    case AgentType.EDIT_AGENT_DATA: {
      return {
        ...state,
        editAgentData: action.payload,
      };
    }
    case AgentType.GET_ALL_AGENT: {
      return {
        ...state,
        allAgents: action.payload.data,
      };
    }

    default:
      return state;
  }
}

export default AgentReducer;
