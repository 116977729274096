import { AppointmentType } from "./Appointment.type";

const initialState = {
  appointmentData: {},
};

function AppointmentReducer(state = initialState, action) {
  switch (action.type) {
    case AppointmentType.GET_APPOINTMENT_DATA: {
      return {
        ...state,
        appointmentData: action?.payload,
      };
    }

    default:
      return state;
  }
}

export default AppointmentReducer;
