import React, { useState } from "react";
import "./NewLogin.css";
import "./fonts/font-awesome-4.7.0/css/font-awesome.min.css";
import "./fonts/iconic/css/material-design-iconic-font.min.css";
import "./css/util.css";
import "./css/main.css";
import {
  swalErrorMessage,
  swalSuccessMessage,
} from "../../Constant/swalMessage";
import { Link, useHistory, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  changePasswordAction,
  resetPasswordAction,
} from "../NewDevelopment/Actions/CommonActions";
import img from "./images/vodex1.svg";
import { isVodexLoggedin } from "../NewDevelopment/CommonComponents/Common";

function VerifyPassword() {
  const dispatch = useDispatch();
  const history = useHistory();
  const [spin, setSpin] = useState(false);
  const [state, setState] = useState<any>({
    password: "",
    cpassword: "",
  });
  const { password, cpassword } = state;
  const [errors, setErrors] = useState(false);
  const location = useLocation();
  const locSearch = new URLSearchParams(location.search);
  let token = locSearch.get("token");

  const resetPassword = async (e) => {
    e.preventDefault();
    setSpin(true);
    // console.log("password", password);
    if (
      password.length >= 8 &&
      /[A-Z]/.test(password) &&
      /[a-z]/.test(password) &&
      /\d/.test(password) &&
      /[!@#$%^&*()_+[\]{}|\\:;'"<>,.?/~`]/.test(password)
    ) {
      // console.log(password);
      // console.log(cpassword);
      if (password === cpassword) {
        let obj = {
          emailToken: token,
          password: password,
        };

        let res: any = await dispatch(changePasswordAction(obj));
        if (res) {
          history.push("/");
        }
      } else {
        swalErrorMessage("Password didn't match");
      }
    } else {
      setErrors(true);
    }

    setSpin(false);
  };

  const handleChange = (name, value) => {
    setState((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  return (
    <div className="limiter">
      <div
        className={`container-login100  ${
          !isVodexLoggedin() && "whitelabel-background"
        }`}
      >
        <div className="wrap-login100 p-l-55 p-r-55 p-t-30 p-b-54">
          <form
            className="login100-form validate-form"
            onSubmit={resetPassword}
          >
            {isVodexLoggedin() && (
              <span className="login100-form-title p-b-10">
                <img src={img} height={"140px"} />
              </span>
            )}
            <span className="login100-form-title p-b-10">Reset</span>
            <div
              className="wrap-input100 validate-input m-b-23"
              data-validate="Password is reauired"
            >
              <span className="label-input100">Password</span>
              <input
                className="input100"
                type="password"
                name="password"
                placeholder="Enter New Password"
                required={true}
                value={password}
                onChange={(e) => handleChange("password", e.target.value)}
              />
              <span className="focus-input100" data-symbol="&#xf206;"></span>
            </div>
            <div
              className="wrap-input100 validate-input m-b-23"
              data-validate="Confirm Password is reauired"
            >
              <span className="label-input100">Confirm Password</span>
              <input
                className="input100"
                type="password"
                name="cpassword"
                placeholder="Confirm Password"
                required={true}
                value={cpassword}
                onChange={(e) => handleChange("cpassword", e.target.value)}
              />
              <span className="focus-input100" data-symbol="&#xf206;"></span>
            </div>

            {errors && (
              <p style={{ color: "red" }}>
                Password must be at least 8 characters and must contain an
                uppercase, lowercase,numbers and special characters
              </p>
            )}
            <div className="container-login100-form-btn">
              <div className="wrap-login100-form-btn">
                <div className="login100-form-bgbtn"></div>
                <button
                  className="login100-form-btn"
                  type="submit"
                  disabled={spin}
                >
                  {spin ? "Please wait..." : "Reset Password"}
                </button>
              </div>
            </div>
            <div className="flex-col-c p-t-50 p-b-10">
              <span className="txt1 p-b-17">Or </span>
            </div>
            <div>
              <span className="text-right p-t-8 p-b-31 ">
                <Link to={"/login"} className="bottom-span">
                  Login
                </Link>
              </span>
              <span className="text-left p-t-8 p-b-31 p-l-160">
                <Link to={"/register"} className="bottom-span">
                  Register
                </Link>
              </span>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default VerifyPassword;
