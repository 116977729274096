import React, {
  useState,
  useContext,
  useRef,
  Dispatch,
  useEffect,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import "./NewLogin.css";
import "./fonts/font-awesome-4.7.0/css/font-awesome.min.css";
import "./fonts/iconic/css/material-design-iconic-font.min.css";
import "./css/util.css";
import "./css/main.css";
import {
  swalErrorMessage,
  swalSuccessMessage,
} from "../../Constant/swalMessage";
import axios from "axios";
import Checkbox from "../../common/components/Checkbox";
import { Link, useHistory } from "react-router-dom";
import { registerNewAccountAction } from "../NewDevelopment/Actions/CommonActions";
import img from "./images/vodex1.svg";
import { isVodexLoggedin } from "../NewDevelopment/CommonComponents/Common";

function Register1() {
  const reducer = useSelector((reducer: any) => reducer);
  const {
    AccountReducer: { whitelabelDetails },
  } = reducer;
  const dispatch = useDispatch();
  const history = useHistory();
  const [spin, setSpin] = useState(false);
  const [googleButtonDisable, setGooogleButtonDisable] = useState(false);
  const [state, setState] = useState<any>({
    email: "",
    password: "",
    cpassword: "",
    agree: "",
    url: "",
  });
  const [errors, setErrors] = useState(false);

  const [msg, setMsg] = useState("");

  const { email, password, cpassword, agree, url } = state;

  useEffect(() => {
    setTimeout(() => setMsg(""), 5000);
  }, [msg !== ""]);
  useEffect(() => {
    const init = async () => {
      let url1 = "";
      if (isVodexLoggedin()) {
        url1 = "https://www.vodex.ai/terms-of-use";
      } else {
        url1 = whitelabelDetails?.termUrl;
      }

      setState((prev) => ({
        ...prev,
        url: url1,
      }));
    };
    init();
  }, [whitelabelDetails]);

  const handleRegister = async (e) => {
    setSpin(true);
    e.preventDefault();
    if (!agree) {
      setMsg(`Please accept terms and conditions.`);
      setSpin(false);

      return;
    }
    if (
      password.length >= 8 &&
      /[A-Z]/.test(password) &&
      /[a-z]/.test(password) &&
      /\d/.test(password) &&
      /[!@#$%^&*()_+[\]{}|\\:;'"<>,.?/~`]/.test(password)
    ) {
      if (password === cpassword) {
        let obj = {
          email: email.trim(),
          password: password,
          confirmPassword: cpassword,
        };

        let res: any = await dispatch(registerNewAccountAction(obj));
        if (res) {
          history.push("/");
        }
      } else {
        setMsg(`Password doesn't match `);
        setTimeout(() => {
          setMsg("");
        }, 5000);
      }
    } else {
      setErrors(true);
    }
    setSpin(false);
  };

  const handleChange = (name, value) => {
    setState((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  return (
    <div className="limiter">
      <div
        className={`container-login100  ${!isVodexLoggedin() && "whitelabel-background"
        }`}
      >
        <div className="wrap-login100 p-l-55 p-r-55 p-t-30 p-b-54">
          <form
            className="login100-form validate-form"
            onSubmit={handleRegister}
          >
            {isVodexLoggedin() && (
              <span className="login100-form-title p-b-1">
                <img src={img} height={"140px"} />
              </span>
            )}
            <span className="login100-form-title p-b-10">Sign Up</span>
            <div
              className="wrap-input100 validate-input m-b-23"
              data-validate="Username is reauired"
            >
              <span className="label-input100">Email</span>
              <input
                className="input100"
                type="email"
                name="email"
                placeholder="Type your email"
                required={true}
                value={email}
                onChange={(e) =>
                  handleChange("email", e.target.value.toLowerCase())
                }
              />
              <span className="focus-input100" data-symbol="&#xf206;"></span>
            </div>

            <div
              className="wrap-input100 validate-input m-b-23"
              data-validate="Password is required"
            >
              <span className="label-input100">Password</span>
              <input
                className="input100"
                type="password"
                name="password"
                placeholder="Type your password"
                required={true}
                value={password}
                onChange={(e) => handleChange("password", e.target.value)}
              />
              <span className="focus-input100" data-symbol="&#xf190;"></span>
            </div>

            <div
              className="wrap-input100 validate-input m-b-23"
              data-validate="Password is required"
            >
              <span className="label-input100">Confirm Password</span>
              <input
                className="input100"
                type="password"
                name="cpassword"
                placeholder="Type your password"
                required={true}
                value={cpassword}
                onChange={(e) => handleChange("cpassword", e.target.value)}
              />
              <span className="focus-input100" data-symbol="&#xf190;"></span>
            </div>

            <div className="text-left p-t-8 p-b-31 ml-4">
              {/* <a href="#">Forgot password?</a> */}
              <div className="form-group">
                <Checkbox
                  id="checkbox_expiry"
                  field="terms"
                  value={agree}
                  label={
                    isVodexLoggedin()
                      ? "I agree to the Vodex terms"
                      : "I agree to the terms"
                  }
                  onChange={() => handleChange("agree", !agree)}
                  link={url}
                />
              </div>
            </div>
            {msg && <p style={{ color: "red" }}>{msg}</p>}
            {errors && (
              <p style={{ color: "red" }}>
                Password must be at least 8 characters must contain an
                uppercase, lowercase,numbers and special characters
              </p>
            )}
            <div className="container-login100-form-btn">
              <div className="wrap-login100-form-btn">
                <div className="login100-form-bgbtn"></div>
                <button
                  className="login100-form-btn"
                  type="submit"
                  disabled={spin}
                >
                  {spin ? "Registering..." : "Register"}
                </button>
              </div>
            </div>
            <div className="flex-col-c p-t-40 p-b-10">
              <span className="txt1 p-b-17">Or </span>
            </div>
            <div>
              <span className="flex-col-c  p-b-10 ">
                <Link to={"/login"} className="bottom-span">
                  Login
                </Link>
              </span>
              <span className="text-left p-t-8 p-b-31 p-l-230"></span>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Register1;
