import React from "react";
import { Modal } from "react-bootstrap";

function ModalWithoutBody({ body, show, toggle }) {
  return (
    <Modal
      show={show}
      onHide={toggle}
      backdrop="static"
      keyboard={false}
      centered={true}
      size="sm"
    >
      <div className="d-flex justify-content-center">{body}</div>
    </Modal>
  );
}

export default ModalWithoutBody;
