import { createStore, applyMiddleware, Store } from "redux";
import thunkMiddleware from "redux-thunk";
import rootReducerUpdated from "../components/NewDevelopment/Store/root.reducers";

const store: Store = createStore(
  rootReducerUpdated,
  applyMiddleware(thunkMiddleware)
);

store.subscribe(() => {});
export default store;
