import React from "react";
import { useMemo } from "react";
import PropTypes from "prop-types";
import { Bar } from "react-chartjs-2";
import Card from "@mui/material/Card";
import SoftBox from "../SoftBox";
import SoftTypography from "../SoftTypography";
import configs from "./configs";

function ReportsBarChart({ color, title, description, chart, data1, allZero }) {
  const { data, options } = configs(
    data1.labels || [],
    data1.datasets[0] || {}
  );

  // console.log("data1data1", data1);
  // console.log("chartchart", chart);
  return (
    <Card sx={{ height: "100%" }}>
      <SoftBox padding="1rem">
        {title || description ? (
          <SoftBox px={description ? 1 : 0} pt={description ? 1 : 0}>
            {title && (
              <SoftBox mb={1}>
                <SoftTypography variant="button">{title}</SoftTypography>
              </SoftBox>
            )}
            <SoftBox mb={2}>
              <SoftTypography
                component="div"
                variant="button"
                fontWeight="regular"
                color="text"
              >
                {description}
              </SoftTypography>
            </SoftBox>
          </SoftBox>
        ) : null}
        {useMemo(
          () => (
            <SoftBox
              variant="gradient"
              bgColor={color}
              borderRadius="lg"
              py={2}
              pr={0.5}
              mb={3}
              height="12.5rem"
            >
              {allZero ? (
                <p
                  className="d-flex justify-content-center"
                  style={{ color: "white", marginTop: "17%" }}
                >
                  No Data Found
                </p>
              ) : (
                <Bar data={data} options={options} />
              )}
            </SoftBox>
          ),
          [data1, color]
        )}
      </SoftBox>
    </Card>
  );
}

// Setting default values for the props of ReportsBarChart
ReportsBarChart.defaultProps = {
  color: "dark",
  description: "",
  items: [],
};

// Typechecking props for the ReportsBarChart
ReportsBarChart.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "dark",
  ]),
  title: PropTypes.string.isRequired,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  chart: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.array, PropTypes.object])
  ).isRequired,
  items: PropTypes.arrayOf(PropTypes.object),
};

export default ReportsBarChart;
