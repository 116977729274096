import React from "react";
import { Table } from "react-bootstrap";

interface CommonTableProps {
  body: React.ReactNode;
  accessLevel?: "read" | "write";
  campAccess?: boolean;
  headers: string[];
}

function CommonTable({ body, headers, accessLevel, campAccess }: CommonTableProps) {
  const head = "Run"
  const shouldFilter = accessLevel !== undefined && campAccess !== undefined && head !== undefined;

  const filteredHeaders = shouldFilter
    ? headers?.filter(
        (header) => !(header.split(`:`)[0] === head &&  (accessLevel === "read") && !campAccess)
      )
    : headers;

  return (
    <Table className="table-centered table-nowrap rounded mb-0" hover>
      <thead className="thead-light">
        <tr>
          {filteredHeaders.map((head) => {
            if (head.includes(":"))
              return (
                <th className={`border-0 text-center`}>{head.split(`:`)[0]}</th>
              );
            else return <th className="border-0">{head}</th>;
          })}
        </tr>
      </thead>
      <tbody>{body}</tbody>
    </Table>
  );
}

export default CommonTable;
