import {
  API,
  BASE_URL_AI,
  BASE_URL_API_V1,
  axiosClient,
} from "../../../Constant/apiConstant";

export const inviteUserService = async (obj) => {
  try {
    let res = await axiosClient.post(
      `${BASE_URL_API_V1}${API.V1.ACCESS.INVITE}`,
      obj
    );
    return res?.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const checkUserService = async (obj) => {
  try {
    let res = await axiosClient.post(
      `${BASE_URL_API_V1}${API.V1.ACCESS.USERS_CHECK}`,
      obj
    );
    return res?.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const acceptInviteService = async (obj) => {
  try {
    let res = await axiosClient.post(
      `${BASE_URL_API_V1}${API.V1.ACCESS.ACCEPT_INVITE}`,
      obj
    );
    return res?.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const getInviteListService = async () => {
  try {
    let res = await axiosClient.get(
      `${BASE_URL_API_V1}${API.V1.ACCESS.INVITED_USERS}`
    );
    return res?.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const deleteInvitedUsersService = async (id) => {
  try {
    let res = await axiosClient.delete(
      `${BASE_URL_API_V1}${API.V1.ACCESS.INVITE_USER}/revoke/${id}`
    );
    return res?.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const editInvitedUserService = async (obj, id) => {
  try {
    let res = await axiosClient.put(
      `${BASE_URL_API_V1}${API.V1.ACCESS.INVITE_USER}/${id}`,
      obj
    );
    return res?.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const getAccountHistoryService = async (obj) => {
  try {
    let res = await axiosClient.get(
      `${BASE_URL_API_V1}${API.V1.ACCESS.ACCOUNT_HISTORY}`,
      { params: obj }
    );
    return res?.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const saveVoiceService = async (obj) => {
  try {
    let res = await axiosClient.post(
      `${BASE_URL_API_V1}${API.V1.VOICE.VOICE}`,
      obj
    );
    return res?.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const cloneVoiceService = async (obj) => {
  try {
    let res = await axiosClient.post(
      `${BASE_URL_AI}${API.V1.VOICE.VOICE_CLONE}`,
      obj
    );
    return res?.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};
export const deleteVoiceService = async (obj) => {
  try {
    let res = await axiosClient.delete(
      `${BASE_URL_AI}${API.V1.VOICE.VOICE_CLONE}/`,
      { data: { ...obj } }
    );
    return res?.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const getVoiceListService = async () => {
  try {
    let res = await axiosClient.get(`${BASE_URL_API_V1}${API.V1.VOICE.VOICE}`);
    return res?.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const sendSmsToSupportService = async (obj) => {
  try {
    let res = await axiosClient.post(
      `${BASE_URL_API_V1}${API.V1.ACCESS.SEND_EMAIL}`,
      obj
    );
    return res?.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};
