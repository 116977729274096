import React, { useState, Dispatch } from "react";
import { useDispatch, useSelector } from "react-redux";
import img from "../../../pic2.jpg";
import { useHistory } from "react-router-dom";
import {
  clearAccountReducerAction,
  logoutAction,
} from "../Store/Account/Account.action";
import { clearProjectReducer } from "../Projects/Store/projects.action";
import { clearCallSettingReducerAction } from "../ProjectSection/CallSettings/Store/callSetting.action";
import { clearAudienceReducer } from "../ProjectSection/AudienceSection/Store/Audience.action";

function TopMenuAccountSubHeader(): JSX.Element {
  const history = useHistory();
  const dispatch: Dispatch<any> = useDispatch();
  const reducer = useSelector((reducer: any) => reducer);
  const {
    AccountReducer: {
      userDetails: { email },
      selectedAccount,
      dashboardData,
    },
  } = reducer;

  const [isShow, setShow] = useState(false);

  return (
    <>
      <li className="nav-item dropdown no-arrow">
        <a
          className="nav-link dropdown-toggle"
          onClick={() => {
            setShow(!isShow);
          }}
          href="# "
          id="userDropdown"
          role="button"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <span
            style={{ color: "white" }}
            className="mr-2 d-none d-lg-inline small cadet"
          >
            {email}
          </span>

          <img
            className="img-profile rounded-circle"
            alt=""
            src={img}
            style={{ zIndex: 901 }}
          />
        </a>

        <div
          className={`dropdown-menu dropdown-menu-right shadow animated--grow-in ${
            isShow ? "show" : ""
          }`}
          aria-labelledby="userDropdown"
        >
          {/* <a
            className="dropdown-item"
            onClick={() => {
              history.push("/account/profile");
            }}
            href="# "
            data-toggle="modal"
            data-target="#logoutModal"
          >
            <i className="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400"></i>
            Profile
          </a> */}
          <a
            className="dropdown-item"
            onClick={async () => {
              await dispatch(clearAccountReducerAction());
              await dispatch(clearProjectReducer());
              await dispatch(clearAccountReducerAction());
              await dispatch(clearCallSettingReducerAction());
              await dispatch(clearAudienceReducer());
              await dispatch(logoutAction(history));
            }}
            href="# "
            data-toggle="modal"
            data-target="#logoutModal"
          >
            <i className="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400"></i>
            Logout
          </a>
        </div>
      </li>
    </>
  );
}

export default TopMenuAccountSubHeader;
