import React, { useEffect } from "react";
import { Alert } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { updateCurrentPath } from "../../../store/actions/root.actions";
function Support() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(updateCurrentPath("Support", ""));
  }, []);
  const tableReturn = () => {
    return (
      <div className="d-flex justify-content-center">
        <Alert variant="warning">
          <span style={{ color: "black" }}>
            For any queries, please reach out to our support team at
            contact@vodex.ai{" "}
          </span>
        </Alert>
      </div>
    );
  };

  return (
    <div>
      <div className="container">
        <div className="row">
          <div className="col-lg-12">{tableReturn()}</div>
        </div>
      </div>
    </div>
  );
}

export default Support;
