import React from 'react';
import { Box, Typography } from '@mui/material';

const SiteUnavailable = () => {
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                minHeight: '100vh',
                backgroundColor: 'black',
                color: 'white',
                textAlign: 'center',
                p: 2,
                width: "100%"
            }}
        >
            <Typography variant="h4" component="div">
                Site Unavailable
            </Typography>
            <Typography variant="h6" component="div" sx={{ mt: 2 }}>
                We're sorry, but this site is currently unavailable. Please try again later.
            </Typography>
        </Box>
    );
};

export default SiteUnavailable;