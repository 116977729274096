import React, { useEffect, useState } from "react";
import CommonModal from "../../../CommonComponents/CommonModal";
import { useDispatch } from "react-redux";
import { getCalllogsAction } from "../Store/Campaign.action";
import { Col, Row } from "react-bootstrap";

function CallLogs({ call_uuid, show, toggle }) {
  const dispatch = useDispatch();
  const [data, setData] = useState<any>([]);
  useEffect(() => {
    const init = async () => {
      let res = await dispatch(getCalllogsAction(call_uuid));

      setData(res);
    };

    init();

    return () => {
      setData([]);
    };
  }, []);

  return (
    <div>
      <CommonModal
        body={
          <>
            <Row
              style={{
                backgroundColor: "black",
                color: "white",
                marginLeft: "0px",
                marginRight: "0px",
              }}
            >
              <Col>
                <p className="ml-2 pt-3" style={{ color: "white" }}>
                  Ingestion Time
                </p>
              </Col>
              <Col>
                {" "}
                <p className="ml-2 mr-2 pt-3" style={{ color: "white" }}>
                  Message
                </p>
              </Col>
              <Col>
                <p
                  className="mr-2 pt-3"
                  style={{ color: "white", float: "right" }}
                >
                  Timestamp
                </p>
              </Col>
            </Row>
            {data.map((d, ind) => (
              <>
                <div
                  className="d-flex"
                  style={{ backgroundColor: "black", color: "white" }}
                >
                  <p className="ml-2 pt-3" style={{ color: "white" }}>
                    {d.ingestionTime}
                  </p>
                  <p className="ml-2 mr-2 pt-3" style={{ color: "white" }}>
                    {d.message}
                  </p>
                  <p className="mr-2 pt-3" style={{ color: "white" }}>
                    {d.timestamp}
                  </p>
                </div>
              </>
            ))}
          </>
        }
        handleSave={false}
        heading={"Logs"}
        show={show}
        size={"xl"}
        toggle={toggle}
      />
    </div>
  );
}

export default CallLogs;
