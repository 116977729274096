import React from "react";
import Drawer from "@material-ui/core/Drawer";

function CommonDrawer({ open, toggle, html }) {
  return (
    <div>
      <Drawer
        anchor={"right"}
        open={open}
        onClose={toggle}
        style={{ zIndex: 999 }}
      >
        {html}
      </Drawer>
    </div>
  );
}

export default CommonDrawer;
