import React, { useEffect } from "react";
import { LoadingDots } from "../NewDevelopment/CommonComponents/Common";
import { useHistory, useLocation } from "react-router-dom";
import { directLogin } from "../NewDevelopment/Actions/CommonActions";
import { useDispatch } from "react-redux";

function DirectLogin() {
  const location = useLocation();
  const locSearch = new URLSearchParams(location.search);
  let ghl_key = locSearch.get("ghl_key");
  let locationId = locSearch.get("location_Id");
  const dispatch = useDispatch();
  const history = useHistory();
  const signIn = async () => {
    localStorage.clear();
    try {
      let obj = {
        locationId: locationId,
        apiKey: ghl_key,
      };
      let res: any = await dispatch(directLogin(obj));
      if (res) {
        const { accounts, lastLogin } = res;
        let find = accounts?.find((acc) => acc?._id === lastLogin?.accountId);
        if (find && !find.accountName) {
          history.push(`/account-name?account-id=${find._id}`);
        } else {
          history.push("/account/dashboard");
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    signIn();
  }, []);
  return (
    <div
      style={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        padding: "10px",
      }}
    >
      <LoadingDots font="22px" />
    </div>
  );
}

export default DirectLogin;
