import { AudienceType } from "./Audience.type";
import { concat, isEmpty, get, cloneDeep } from "lodash";

const initialState = {
  audiences: [],
  audienceCount: 0,
  expand: false,
};

function AudienceReducer(state = initialState, action) {
  switch (action.type) {
    case AudienceType.GET_AUDIENCE_DATA: {
      const updatedArry = cloneDeep(
        setArrayByCase(state.audiences, action.payload)
      );

      return {
        ...state,
        audiences: updatedArry,
        audienceCount: action.payload.totalCount,
      };
    }
    case AudienceType.SET_EXPAND_VIEW: {
      return {
        ...state,
        expand: action.payload.expand,
      };
    }
    case AudienceType.CLEAR_AUDIENCE_DATA: {
      return {
        ...initialState,
      };
    }

    default:
      return state;
  }
}

export default AudienceReducer;

const setArrayByCase = (array, payload) => {
  if (!isEmpty(get(payload, "searchText")) && get(payload, "isScrolled")) {
    array = [...array, ...payload.data];
    return array;
  } else if (!isEmpty(get(payload, "searchText"))) {
    array = payload.data;
    return array;
  } else if (get(payload, "isScrolled")) {
    array = concat(array, payload.data);
    return array;
  }
  return payload.data;
};
