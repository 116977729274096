import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { verifyEmailAction } from "../Actions/CommonActions";
import img from "./4.png";
import { LoadingDots, isVodexLoggedin } from "../CommonComponents/Common";
function AccountVerfication() {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const locSearch = new URLSearchParams(location.search);
  let token = locSearch.get("token");

  const [spin, setSpin] = useState(true);
  useEffect(() => {
    const init = async () => {
      if (!token) {
        history.push("/");
      }
      let currency = "usd";
      await dispatch(verifyEmailAction({ token, currency }));
      setSpin(false);
    };
    init();
  }, []);
  return (
    <div className="limiter">
      <div
        className={`container-login100  ${
          !isVodexLoggedin() && "whitelabel-background"
        }`}
      >
        <div className="wrap-login100 p-l-55 p-r-55 p-t-65 p-b-54">
          <div className="container-login100-form-btn">
            <img src={img} height={"120px"} width={"120px"} className="mb-3" />

            <div className="wrap-login100-form-btn">
              <div className="login100-form-bgbtn"></div>
              <button
                className="login100-form-btn"
                type="submit"
                onClick={() => {
                  history.push("/");
                }}
                disabled={spin}
              >
                {spin ? (
                  <LoadingDots />
                ) : (
                  `Continue ${isVodexLoggedin() ? "to Vodex" : ""}`
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AccountVerfication;
