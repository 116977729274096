import { combineReducers } from "redux";
import ProjectReducer from "../Projects/Store/projects.reducer";
import { UPDATE_CURRENT_PATH } from "../../../store/actions/root.actions";
import AccountReducer from "./Account/Account.reducer";
import CallSettingReducer from "../ProjectSection/CallSettings/Store/callSetting.reducer";
import AudienceReducer from "../ProjectSection/AudienceSection/Store/Audience.reducer";
import RecordingReducer from "../ProjectSection/RecordingSection/Store/Recording.reducer";
import WhatsappReducer from "../ProjectSection/Whatsapp/Store/Whatsapp.reducer";
import SmsReducer from "../ProjectSection/SMS/Store/Sms.reducer";
import CampaignReducer from "../ProjectSection/CampaignSection/Store/Campaign.reducer";
import AgentReducer from "../ProjectSection/Agents/Store/Agent.reducer";
import AdminReducer from "../Admin/Store/admin.reducer";
import AccessReducer from "../AccessManger/Store/access.reducer";
import NotificationReducer from "../../../store/reducers/notification.reducer";
import AppointmentReducer from "../ProjectSection/Appointments/Store/Appointment.reducer";

const initialState = {
  page: { area: "home", subArea: "" },
};

function rootReducer(state = initialState, action) {
  switch (action.type) {
    case UPDATE_CURRENT_PATH:
      return { ...state, page: { area: action.area, subArea: action.subArea } };
    default:
      return state;
  }
}

const rootReducerUpdated = combineReducers({
  root: rootReducer,
  ProjectReducer: ProjectReducer,
  AccountReducer: AccountReducer,
  CallSettingReducer: CallSettingReducer,
  AudienceReducer: AudienceReducer,
  RecordingReducer: RecordingReducer,
  WhatsappReducer: WhatsappReducer,
  SmsReducer: SmsReducer,
  CampaignReducer: CampaignReducer,
  AgentReducer: AgentReducer,
  AdminReducer: AdminReducer,
  AccessReducer: AccessReducer,
  NotificationReducer: NotificationReducer,
  AppointmentReducer: AppointmentReducer,
});

export default rootReducerUpdated;
