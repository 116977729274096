import React, { useEffect, useState } from "react";
import "./TopMenu.css";
import { useDispatch, useSelector } from "react-redux";
import TopMenuAccountSubHeader from "./TopMenuSubHeader";
import { saveSelectedAccount } from "../Store/Account/Account.action";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { axiosClient } from "../../../Constant/apiConstant";
import { checkRolesAccess } from "../CommonComponents/Common";
import { lastLoginTrackAction } from "../Actions/CommonActions";
import Select from "react-select";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import { components } from "react-select"

const TopMenuAccount: React.FC = () => {


  const dispatch = useDispatch();
  const page = useSelector((state: any) => state?.root?.page);
  const reducer = useSelector((reducer: any) => reducer);
  const {
    AccountReducer: {
      userDetails: { email, accounts, _id },
      selectedAccount,
    },
    ProjectReducer: { projectList },
  } = reducer;

  const [isShow, setShow] = useState(false);

  const changeAccount = async (account) => {
    let lastLoginObj = {
      accountUrl: account?.accountUrl,
      userId: _id,
      accountId: account?._id,
      track: true,
    };
    let res1: any = await dispatch(lastLoginTrackAction(lastLoginObj));
    await dispatch(saveSelectedAccount(account));
    if (res1) {
      let goTo = checkRolesAccess(res1);
      if (goTo === "dashboard") {
        history.push("/account/dashboard");
      }
      if (goTo === "project") {
        history.push("/account/projects");
      }
    }
  };
  const [state, setState] = useState({
    projectName: "",
  });
  const { projectName } = state;

  const { pathname } = useLocation();
  const projectId = pathname.split("/")[3];

  useEffect(() => {
    if (projectList?.length > 0) {
      let fn = projectList?.find((o) => o?._id === projectId)?.projectName;
      setState((prev) => ({
        ...prev,
        projectName: fn,
      }));
    }
  }, [projectList, projectId]);
  const history = useHistory();

  const changeProject = async (project) => {
    localStorage.removeItem("campaignSearchText");
    localStorage.removeItem("campaignpageIndex");
    localStorage.removeItem("campaignRunpageIndex");
    localStorage.removeItem("campaignRunDetailsIndex");
    localStorage.removeItem("searchTextAud");
    localStorage.removeItem("form-data");
    axiosClient.defaults.headers.common["projectId"] = project?._id;
    if (project?.botPlatform?.includes("llm")) {
      history.push(`/account/project/${project?._id}/agents`);
    } else {
      history.push(`/account/project/${project?._id}/campaigns`);
    }
  };
  return (
    <nav
      style={{ backgroundImage: "linear-gradient(310deg, #3a416f, #141727)" }}
      className="navbar navbar-expand topbar  static-top shadow"
    >
      <span className="display-mobile-none">
        {/* <ol className="breadcrumb dark-breadcrumb ">
          <li className="breadcrumb-item">
            <a href="# ">{page ? page.area : null}</a>
          </li>
          <li className="breadcrumb-item">
            <a href="# ">{page ? page.subArea : null}</a>
          </li>
        </ol> */}
        <Breadcrumbs
          aria-label="breadcrumb"
          style={{ width: "220px", color: "white" }}
        >
          <Typography color="inherit">{page ? page.area : null}</Typography>

          <Typography color="inherit"> {page ? page.subArea : null}</Typography>
        </Breadcrumbs>
      </span>
      <span
        className="w-70"
        style={{
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <span
          style={{
            fontSize: "17px",
            color: "white",
            marginRight: "7px",
            marginTop: "7px",
          }}
        >
          {projectId ? "Projects:" : "Accounts:"}
        </span>
        <span>
          {projectId ? (
            <Select
              maxMenuHeight={180}
              className="basic-single project-select"
              classNamePrefix="select"
              value={projectList?.find((project) => project._id === projectId)}
              isDisabled={false}
              isSearchable={true}
              options={projectList}
              getOptionLabel={(e: any) => e.projectName}
              getOptionValue={(e: any) => e._id}
              onChange={(e) => {
                localStorage.removeItem('starred');
                changeProject(e);
              }}
              placeholder="Select Project"
            />
          ) : (
            <Select
              maxMenuHeight={180}
              className="basic-single  project-select"
              classNamePrefix="select"
              value={accounts?.find(
                (project) =>
                  project.accountName === selectedAccount?.accountName
              )}
              isDisabled={false}
              isSearchable={true}
              options={accounts}
              getOptionLabel={(e: any) => e.accountName}
              getOptionValue={(e: any) => e._id}
              onChange={(e) => {
                changeAccount(e);
              }}
              placeholder="Select Account"
              components={{
                Input: (props) => (
                  <components.Input {...props} maxLength={30} />
                ),
              }}
            />
          )}
        </span>
      </span>
      <ul className="navbar-nav ml-auto">
        <div className="topbar-divider d-none d-sm-block"></div>
        <TopMenuAccountSubHeader />
      </ul>
    </nav>
  );
};

export default TopMenuAccount;
