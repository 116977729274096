import * as React from "react";
import { SnackbarProvider, useSnackbar } from "notistack";
import { useDispatch, useSelector } from "react-redux";
import { removeNotificationAction } from "../Store/Account/Account.action";

function MyApp() {
  const { enqueueSnackbar } = useSnackbar();

  const handleClickVariant = (text, variant) => () => {
    enqueueSnackbar(text, { variant });
  };

  const dispatch = useDispatch();
  const notifications = useSelector(
    (state: any) => state.AccountReducer.notifications
  );
  const NotificationList = notifications?.map((noti) => {
    setTimeout(() => {
      dispatch(removeNotificationAction(noti.id));
    }, 1000);
    return (
      <div key={`notification_${noti.id}`}>
        {handleClickVariant(noti.text, noti.variant)()}
      </div>
    );
  });

  return <React.Fragment>{NotificationList}</React.Fragment>;
}

export default function IntegrationNotistack() {
  return (
    <SnackbarProvider maxSnack={3}>
      <MyApp />
    </SnackbarProvider>
  );
}
