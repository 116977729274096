import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Row,
} from "reactstrap";
import { LoadingDots, PAGE_SIZE_50 } from "../../../CommonComponents/Common";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllCampaignDetailsAction,
  getCallHistoryAction,
  getCallHistoryCountAction,
  getCampaignDetailsAction,
} from "../Store/Campaign.action";
import moment, { max, min } from "moment";
import "./CampaignDetails.css";

function FilterDrawerBody({ obj, all = false, main = false }) {
  const {
    projectId,
    campaignRuns,
    setMainState,
    trackEnabledRecording,
    mainState,
    campaignId,
    startDate,
    endDate,
    selectedProjectId
  } = obj;
  const reducer: any = useSelector((state) => state);
  const dispatch = useDispatch();
  const {
    AccountReducer: { constantData },
    RecordingReducer: { recordings },
  } = reducer;
  const CALL_STATUS = constantData?.callStatus;
  const [state, setState] = useState<any>({
    outComeArr: [],
    loading: false,
    error: false,
  });
  const { durationFilter, statusFilter, outComefilter, searchText } = mainState;
  const { outComeArr, loading, error } = state;

  useEffect(() => {
    setState((prev) => ({
      ...prev,
      outComeArr: trackEnabledRecording,
    }));
  }, [trackEnabledRecording]);

  let arraMapped: any = [
    {
      title: "Filter Calls with duration",
      innerMap: ["0-15 sec", "15-30 sec", "30-45 sec", "45+ sec", "Custom"],
      valueAssigned: "durationFilter",
      title1: "Duration",
    },
  ];
  if (outComeArr?.length > 0) {
    arraMapped = [
      {
        title: "Filter Calls with outcome",
        isObject: true,
        innerMap: outComeArr,
        valueAssigned: "outComefilter",
        title1: "outcome",
      },
      ...arraMapped,
    ];
  }

  const onChange = (name, value) => {
    if (name === "outComefilter") {
      let arr = outComefilter;
      let find = arr.find((o) => o === value.tag);
      if (find) {
        arr = arr.filter((o) => {
          return o !== value.tag;
        });
      } else {
        arr.push(value.tag);
      }
      setMainState((prev) => ({
        ...prev,
        outComefilter: arr,
      }));
    } else {
      if (name === "statusFilter") {
        let arr = statusFilter;
        let find = arr.find((o) => o === value);
        if (find) {
          arr = arr.filter((o) => {
            return o !== value;
          });
        } else {
          arr.push(value);
        }
        setMainState((prev) => ({
          ...prev,
          statusFilter: arr,
        }));
      } else {
        setMainState((prev) => ({
          ...prev,
          [name]: value,
        }));
      }
    }
  };
  const clearFilter = async (val) => {
    if (val === "all") {
      setMainState((prev) => ({
        ...prev,
        durationFilter: "",
        outComefilter: [],
        statusFilter: [],
        showCreateButton: false,
        min: "",
        max: ""
      }));

      let obj = {
        pageIndex: 1,
        pageSize: PAGE_SIZE_50,
        live: false,
        duration: "",
        callStatus: [],
        outcome: [],
        projectId:selectedProjectId || "",
        searchText,
      };
      if (main) {
        obj["from"] = moment(startDate).format("YYYY-MM-DD");
        obj["to"] = moment(endDate).format("YYYY-MM-DD");
        await dispatch(getCallHistoryAction(obj));
        await dispatch(getCallHistoryCountAction(obj));
      } else {
        if (all) {
          await  dispatch(getAllCampaignDetailsAction(campaignId, { ...obj, isDocsOnly: true }));
          await  dispatch(getAllCampaignDetailsAction(campaignId, { ...obj, isDocsOnly: false }));
        } else {
          await dispatch(
            getCampaignDetailsAction(projectId, campaignRuns, obj)
          );
        }
      }

      setMainState((prev) => ({
        ...prev,
        filterDrawer: false,
        pageIndex: 1,
      }));
      return;
    }
    let kl = val === "outComefilter" || val === "statusFilter" ? [] : "";

    let obj2 = {
      durationFilter: "duration",
      statusFilter: "callStatus",
      outComefilter: "outcome",
    };
    let obj = {
      pageIndex: 1,
      pageSize: PAGE_SIZE_50,
      live: false,
      duration:
        durationFilter === "Custom"
          ? `${mainState?.min}-${mainState?.max}`
          : durationFilter?.split(" ")[0],
      callStatus: statusFilter,
      outcome: outComefilter,
      projectId: selectedProjectId || "",
      searchText
    };
    delete obj[obj2[val]];
    if (main) {
      obj["from"] = moment(startDate).format("YYYY-MM-DD");
      obj["to"] = moment(endDate).format("YYYY-MM-DD");
      await dispatch(getCallHistoryAction(obj));
      await dispatch(getCallHistoryCountAction(obj));
    } else {
      if (all) {
        await  dispatch(getAllCampaignDetailsAction(campaignId, { ...obj, isDocsOnly: true }));
        await  dispatch(getAllCampaignDetailsAction(campaignId, { ...obj, isDocsOnly: false }));
    } else {
        await dispatch(getCampaignDetailsAction(projectId, campaignRuns, obj));
      }
    }

    setMainState((prev) => ({
      ...prev,
      [val]: kl,
      pageIndex: 1,
    }));
  };

  const applyFilter = async () => {
    // console.log(mainState?.min?.trim(), mainState?.max?.trim());
    if (
      durationFilter === "Custom" &&
      mainState?.min?.trim() === "" &&
      mainState?.max?.trim() === ""
    ) {
      setState((prev) => ({
        ...prev,
        error: true,
      }));
      return false;
    }
    setState((prev) => ({
      ...prev,
      loading: true,
    }));
    let obj = {
      pageIndex: 1,
      pageSize: PAGE_SIZE_50,
      live: false,
      duration:
        durationFilter === "Custom"
          ? `${mainState?.min}-${mainState?.max}`
          : durationFilter?.split(" ")[0],
      callStatus: statusFilter,
      outcome: outComefilter,
      projectId: selectedProjectId || "",
      searchText
    };
    if (main) {
      obj["from"] = moment(startDate).format("YYYY-MM-DD");
      obj["to"] = moment(endDate).format("YYYY-MM-DD");
      await dispatch(getCallHistoryAction(obj));
      await dispatch(getCallHistoryCountAction(obj));
    } else {
      if (all) {
        await  dispatch(getAllCampaignDetailsAction(campaignId, { ...obj, isDocsOnly: true }));
        await  dispatch(getAllCampaignDetailsAction(campaignId, { ...obj, isDocsOnly: false }));
    } else {
        await dispatch(getCampaignDetailsAction(projectId, campaignRuns, obj));
      }
    }
    setState((prev) => ({
      ...prev,
      loading: false,
    }));
    setMainState((prev) => ({
      ...prev,
      showCreateButton: true,
      filterDrawer: false,
      pageIndex: 1,
    }));
  };

  const getDisabled = () => {
    if (
      durationFilter ||
      statusFilter?.length > 0 ||
      outComefilter.length > 0
    ) {
      return false;
    } else return true;
  };

  const onChange1 = (e) => {
    e.persist();
    if (e.target.value && Number(e.target.value) <= 0) {
      return;
    } else {
      setMainState((prev) => ({
        ...prev,
        [e.target.name]: e.target.value,
      }));
    }
  };
  return (
    <div style={{ minWidth: "500px", backgroundColor: "whitesmoke" }}>
      {" "}
      <Col>
        <Card className="mt-2">
          <CardHeader>
            <CardTitle tag="h6">Filter Calls with status</CardTitle>
          </CardHeader>
          <CardBody>
            <ul className="list-unstyled team-members">
              {[
                "703",
                "704",
                "705",
                "706",
                "707",
                "708",
                "709",
                "710",
                "711",
                "712",
                "713",
                "716",
                "718",
              ].map((innerArr) => {
                return (
                  <li key={`staat${innerArr}`}>
                    <Row className="mt-4">
                      <Col md="7" xs="7">
                        {CALL_STATUS[innerArr]}
                        <br />
                      </Col>
                      <Col className="text-right" md="3" xs="3">
                        <div className="container">
                          <input
                            type="checkbox"
                            id="cbx2"
                            style={{ display: "none" }}
                            checked={
                              mainState["statusFilter"].includes(innerArr)
                                ? true
                                : false
                            }
                          />
                          <label htmlFor="cbx2" className="check">
                            <svg
                              width="18px"
                              height="18px"
                              viewBox="0 0 18 18"
                              onClick={() => onChange("statusFilter", innerArr)}
                            >
                              <path d="M 1 9 L 1 9 c 0 -5 3 -8 8 -8 L 9 1 C 14 1 17 5 17 9 L 17 9 c 0 4 -4 8 -8 8 L 9 17 C 5 17 1 14 1 9 L 1 9 Z"></path>
                              <polyline points="1 9 7 14 15 4"></polyline>
                            </svg>
                          </label>
                        </div>
                      </Col>
                    </Row>
                  </li>
                );
              })}
            </ul>
            <Button onClick={() => {
              setMainState((prev) => ({
                ...prev,
                min: "",
                max: "",
                showCreateButton: false
              }))
              clearFilter("statusFilter")}}>
              Clear Status filter
            </Button>
          </CardBody>
        </Card>
        {arraMapped.map((arr) => (
          <Card className="mt-2" key={`call-${arr?.title}`}>
            <CardHeader>
              <CardTitle tag="h6">{arr?.title}</CardTitle>
            </CardHeader>
            <CardBody>
              <ul className="list-unstyled team-members">
                {arr.innerMap?.map((innerArr) => {
                  let dur = arr?.title === "Filter Calls with status";
                  return (
                    <li key={`inner${innerArr}`}>
                      <Row className="mt-4">
                        <Col md="7" xs="7">
                          {!arr?.isObject &&
                            (dur ? CALL_STATUS[innerArr] : innerArr)}
                          {arr?.isObject && innerArr?.name}
                          <br />
                        </Col>
                        <Col className="text-right" md="3" xs="3">
                          <div className="container">
                            <input
                              type="checkbox"
                              id="cbx2"
                              style={{ display: "none" }}
                              checked={
                                arr?.isObject
                                  ? outComefilter.find(
                                      (o) => o === innerArr?.tag
                                    )
                                  : mainState[arr?.valueAssigned] ===
                                      innerArr ||
                                    mainState[arr?.valueAssigned].includes(
                                      innerArr
                                    )
                                  ? true
                                  : false
                              }
                            />
                            <label htmlFor="cbx2" className="check">
                              <svg
                                width="18px"
                                height="18px"
                                viewBox="0 0 18 18"
                                onClick={() =>
                                  onChange(arr?.valueAssigned, innerArr)
                                }
                              >
                                <path d="M 1 9 L 1 9 c 0 -5 3 -8 8 -8 L 9 1 C 14 1 17 5 17 9 L 17 9 c 0 4 -4 8 -8 8 L 9 17 C 5 17 1 14 1 9 L 1 9 Z"></path>
                                <polyline points="1 9 7 14 15 4"></polyline>
                              </svg>
                            </label>
                          </div>
                        </Col>
                      </Row>
                    </li>
                  );
                })}
              </ul>
              {durationFilter === "Custom" && (
                <div>
                  <label htmlFor="">Custom Duration</label>
                  <div className="d-flex">
                    <input
                      placeholder="from (in sec.)"
                      name="min"
                      min={1}
                      type="number"
                      className="form-control mr-1"
                      value={mainState?.min}
                      onChange={onChange1}
                    />
                    <input
                      placeholder="to (in sec.)"
                      name="max"
                      min={1}
                      type="number"
                      className="form-control"
                      value={mainState?.max}
                      onChange={onChange1}
                    />
                  </div>
                  {error && (
                    <span style={{ color: "red" }}>
                      Both fields are required
                    </span>
                  )}
                </div>
              )}
              <Button
                onClick={() => {
                  setMainState((prev) => ({
                    ...prev,
                    min: "",
                    max: "",
                    showCreateButton: false
                  }))
                  clearFilter(arr.valueAssigned)
                }}
                disabled={arr?.innerMap?.length === 0}
              >
                Clear {arr.title1} filter
              </Button>
            </CardBody>
          </Card>
        ))}

        <Button onClick={() => clearFilter("all")}>Clear all filter</Button>
        <Button
          disabled={getDisabled() || loading}
          onClick={() => applyFilter()}
        >
          {loading ? <LoadingDots /> : "Apply filter"}
        </Button>
      </Col>
    </div>
  );
}

export default FilterDrawerBody;
