import { cloneDeep, setArrayByCase } from "../../CommonComponents/Common";
import { adminType } from "./admin.type";

const initialState = {
  usersList: [],
  usageList: [],
  rechargeHistoryList: [],
  spentHistoryList: [],
  subAccountRechargeHistory: [],
};

function AdminReducer(state = initialState, action) {
  switch (action.type) {
    case adminType.GET_USERS_LIST: {
      return { ...state, usersList: action.payload };
    }
    case adminType.GET_USERS_USAGE_LIST: {
      return { ...state, usageList: action.payload };
    }
    case adminType.GET_RECHARGE_HISTORY: {
      const updatedArry = cloneDeep(
        setArrayByCase(state.rechargeHistoryList, action.payload)
      );
      return {
        ...state,
        rechargeHistoryList: updatedArry,
        totalRechargeCount: action.payload.totalRechargeCount,
      };
    }
    case adminType.GET_SUB_ACCOUNT_RECHARGE_HISTORY: {
      const updatedArry = cloneDeep(
        setArrayByCase(state.subAccountRechargeHistory, action.payload)
      );
      return {
        ...state,
        subAccountRechargeHistory: updatedArry,
        // totalRechargeCount: action.payload.totalRechargeCount,
      };
    }
    case adminType.GET_SPENT_HISTORY: {
      const updatedArry = cloneDeep(
        setArrayByCase(state.spentHistoryList, action.payload)
      );
      return {
        ...state,
        spentHistoryList: updatedArry,
        totalSpentCount: action.payload.totalSpentCount,
      };
    }

    default:
      return state;
  }
}

export default AdminReducer;
