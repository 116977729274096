import {
  API,
  BASE_URL_AI,
  BASE_URL_API_V1,
  axiosClient,
} from "../../../Constant/apiConstant";

export const getAgentService = async (id) => {
  try {
    let res = await axiosClient.get(
      `${BASE_URL_API_V1}${API.V1.AGENT.AGENT}/${id}`
    );
    return res?.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};
export const getAllAgentService = async () => {
  try {
    let res = await axiosClient.get(`${BASE_URL_API_V1}${API.V1.AGENT.AGENT}`);
    return res?.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};
export const importAgentService = async (obj) => {
  try {
    let res = await axiosClient.post(
      `${BASE_URL_API_V1}${API.V1.AGENT.IMPORT_AGENT}`,
      obj
    );
    return res?.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const saveAgentService = async (data) => {
  try {
    let res = await axiosClient.post(
      `${BASE_URL_API_V1}${API.V1.AGENT.AGENT}`,
      data
    );
    return res?.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const deleteAgentService = async (id) => {
  try {
    let res = await axiosClient.delete(
      `${BASE_URL_API_V1}${API.V1.AGENT.AGENT}/${id}`
    );
    return res?.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const editAgentService = async (id, data) => {
  try {
    let res = await axiosClient.put(
      `${BASE_URL_API_V1}${API.V1.AGENT.AGENT}/${id}`,
      data
    );
    return res?.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const setDeafaultAgentService = async (data, id) => {
  try {
    let res = await axiosClient.post(
      `${BASE_URL_API_V1}${API.V1.AGENT.DEFAULT_SET}/${id}`,
      data
    );
    return res?.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const getPossibleObjectionsAgentService = async (data, id) => {
  try {
    let res = await axiosClient.post(
      `${BASE_URL_AI}${API.V1.AGENT.AGENT_OBJECTION}`,
      data
    );
    return res?.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const getChatIdAgentService = async (data, id) => {
  try {
    let res = await axiosClient.post(
      `${BASE_URL_AI}${API.V1.AGENT.CHAT_ID}`,
      data
    );
    return res?.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const handleChatAgentService = async (data, id) => {
  try {
    let res = await axiosClient.post(
      `${BASE_URL_AI}${API.V1.AGENT.CHAT}`,
      data
    );
    return res?.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const handleOnboardingChatAgentService = async (data, id) => {
  try {
    let res = await axiosClient.post(
      `${BASE_URL_AI}${API.V1.AGENT.ONBOARDING_CHAT}`,
      data
    );
    return res?.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const generatePromptService = async (data, id) => {
  try {
    let res = await axiosClient.post(
      `${BASE_URL_AI}${API.V1.AGENT.GENERATE_PROMPT}`,
      data
    );
    return res?.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const generateVoiceService = async (data, id) => {
  try {
    let res = await axiosClient.post(
      `${BASE_URL_AI}${API.V1.AGENT.GENERATE_VOICE}`,
      data
    );
    return res?.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const handleEditChatAgentService = async (data, id) => {
  try {
    let res = await axiosClient.put(
      `${BASE_URL_AI}${API.V1.AGENT.CHAT}/update/${id}`,
      data
    );
    return res?.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const callAgentService = async (data) => {
  try {
    let res = await axiosClient.post(
      `${BASE_URL_API_V1}${API.V1.CAMPAIGN.TRIGGER_CALL_DIRECTLY}`,
      data
    );
    return res?.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};
