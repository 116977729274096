import { AccountsType } from "./Account.type";

let leftMenuArrayAccount1 = [
  {
    name: "Phone Bot",
    innerArr: [
      {
        name: "Projects",
        link: "/account/projects",
        icon: "tasks",
        fromAccount: true,
      },
      {
        name: "profile",
        link: "/account/profile",
        icon: "user-circle",
        fromAccount: true,
      },
      {
        name: "Payments",
        link: "/account/payments",
        icon: "money-check-alt",
        fromAccount: true,
      },
    ],
  },
];

let leftMenuArrayProject1 = [
  {
    name: "Phone Bot",
    innerArr: [
      {
        name: "Agents",
        link: "/agents",
        icon: "robot",
      },
      {
        name: "recordings",
        link: `/recordings`,
        icon: "headphones",
      },
      {
        name: "audiences",
        link: `/audiences`,
        icon: "users",
      },

      // {
      //   name: "Whatsapp",
      //   link: `/whatsapp-settings`,
      //   icon: "whatsapp",
      // },
      // {
      //   name: "SMS",
      //   link: `/sms-settings`,
      //   icon: "sms",
      // },
      {
        name: "Campaigns",
        link: `/campaigns`,
        icon: "user",
      },
    ],
  },
  {
    name: "Project Settings",
    innerArr: [
      {
        name: "Call Settings",
        link: `/call-settings`,
        icon: "phone-square",
      },
      {
        name: "Messaging",
        link: `/common`,
        icon: "cogs",
      },
      // {
      //   name: "Integration",
      //   link: `/integration`,
      //   icon: "cog",
      // },
      // {
      //   name: "Advance Settings",
      //   link: `/advance-settings`,
      //   icon: "cogs",
      // },
    ],
  },
];

const initialState = {
  userDetails: {},
  selectedAccount: {},
  leftMenuRender: leftMenuArrayAccount1,
  constantData: {},
  dashboardData: {},
  chartData: {},
  whitelabelDetails: {},
  fetched: false,
  accountRoles: {},
  notifications: [],
};

function AccountReducer(state = initialState, action) {
  switch (action.type) {
    case AccountsType.GET_CONSTANT_DATA: {
      return {
        ...state,
        constantData: action.payload,
      };
    }
    case AccountsType.GET_ACCOUNT_DETAILS: {
      localStorage.setItem(
        "selectedAccount",
        JSON.stringify(action?.payload?.selectedAccount)
      );

      return {
        ...state,
        userDetails: action?.payload?.data,
        selectedAccount: action?.payload?.selectedAccount,
      };
    }
    case AccountsType.SELECT_ACCOUNT: {
      localStorage.setItem("selectedAccount", JSON.stringify(action.payload));
      return { ...state, selectedAccount: action.payload };
    }
    case AccountsType.CHANGE_MENU: {
      return {
        ...state,
        leftMenuRender:
          action.payload === "project"
            ? leftMenuArrayProject1
            : leftMenuArrayAccount1,
      };
    }
    case AccountsType.GET_DASHBOARD_DATA: {
      return { ...state, dashboardData: action.payload };
    }
    case AccountsType.GET_CHART_DATA: {
      return { ...state, chartData: action.payload };
    }
    case AccountsType.GET_WHITELABEL_DETAILS: {
      return {
        ...state,
        whitelabelDetails: action.payload.data,
        fetched: action.payload.fetched,
      };
    }
    case AccountsType.ACCOUNT_ROLES: {
      return {
        ...state,
        accountRoles: action.payload,
      };
    }

    case AccountsType.CLEAR_ACCOUNT_REDUCER: {
      return { ...initialState };
    }
    case AccountsType.ADD_NOTIFICATION: {
      let maxId = Math.max.apply(
        Math,
        state.notifications.map((o) => o.id)
      );
      if (maxId === -Infinity) {
        maxId = 0;
      }
      let newItem = {
        id: maxId + 1,
        date: new Date(),
        variant: action.variant,
        text: action.text,
      };
      return { ...state, notifications: [...state.notifications, newItem] };
    }
    case AccountsType.REMOVE_NOTIFICATION: {
      return {
        ...state,
        notifications: state.notifications.filter(
          (Notification) => Notification.id !== action.id
        ),
      };
    }

    default:
      return state;
  }
}

export default AccountReducer;
