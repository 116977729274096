import {
  API,
  BASE_URL_API_V1,
  axiosClient,
} from "../../../Constant/apiConstant";

export const getAudienceService = async (id, obj) => {
  try {
    let res = await axiosClient.get(
      `${BASE_URL_API_V1}${API.V1.AUDIENCE.AUDIENCE}/${id}`,
      { params: obj }
    );
    return res?.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};
export const getAudienceServiceForSelect = async (id, obj) => {
  try {
    let res = await axiosClient.get(
      `${BASE_URL_API_V1}${API.V1.AUDIENCE.SELECT_AUDIENCE}/${id}`,
      { params: obj }
    );
    return res?.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};
export const saveAudienceService = async (data) => {
  try {
    let res = await axiosClient.post(
      `${BASE_URL_API_V1}${API.V1.AUDIENCE.AUDIENCE}`,
      data
    );
    return res?.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const deleteAudienceService = async (id) => {
  try {
    let res = await axiosClient.delete(
      `${BASE_URL_API_V1}${API.V1.AUDIENCE.AUDIENCE}/${id}`
    );
    return res?.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const editAudienceService = async (id, data) => {
  try {
    let res = await axiosClient.put(
      `${BASE_URL_API_V1}${API.V1.AUDIENCE.AUDIENCE}/${id}`,
      data
    );
    return res?.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const saveAudienceExcelService = async (data) => {
  try {
    let res = await axiosClient.post(
      `${BASE_URL_API_V1}${API.V1.AUDIENCE.AUDIENCE_EXCEL}`,
      data
    );
    return res?.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const editAudienceExcelService = async (id, data) => {
  try {
    let res = await axiosClient.put(
      `${BASE_URL_API_V1}${API.V1.AUDIENCE.AUDIENCE_EXCEL}/${id}`,
      data
    );
    return res?.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};
