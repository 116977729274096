import {
  API,
  BASE_URL_API_V1,
  axiosClient,
} from "../../../Constant/apiConstant";

export const getProjectsService = async () => {
  try {
    let res = await axiosClient.get(`${BASE_URL_API_V1}${API.V1.PROJECT}`);
    return res?.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const getProjectByIdService = async (id) => {
  try {
    let res = await axiosClient.get(
      `${BASE_URL_API_V1}${API.V1.PROJECT}/${id}`
    );
    return res?.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const saveProjectsService = async (data) => {
  try {
    let res = await axiosClient.post(
      `${BASE_URL_API_V1}${API.V1.PROJECT}`,
      data
    );

    return res?.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const editProjectsService = async (data, id) => {
  try {
    let res = await axiosClient.put(
      `${BASE_URL_API_V1}${API.V1.PROJECT}/${id}`,
      data
    );
    return res?.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const deleteProjectsService = async (id) => {
  try {
    let res = await axiosClient.delete(
      `${BASE_URL_API_V1}${API.V1.PROJECT}/${id}`,
      {
        params: { projectId: id },
      }
    );
    return res?.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const getAdvanceSettingsService = async (id) => {
  try {
    let res = await axiosClient.get(
      `${BASE_URL_API_V1}${API.V1.ADVANCE_SETTINGS}/${id}`
    );
    return res?.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const saveAdvanceSettingsService = async (data, id) => {
  try {
    let res = await axiosClient.post(
      `${BASE_URL_API_V1}${API.V1.ADVANCE_SETTINGS}/${id}`,
      data
    );
    return res?.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};
