import { createTheme } from "@mui/material/styles";
import colors from "../theme/base/colors";
import breakpoints from "../theme/base/breakpoints";
import typography from "../theme/base/typography";
import boxShadows from "../theme/base/boxShadows";
import borders from "../theme/base/borders";
import globals from "../theme/base/globals";
import boxShadow from "../theme/functions/boxShadow";
import hexToRgb from "../theme/functions/hexToRgb";
import linearGradient from "../theme/functions/linearGradient";
import pxToRem from "../theme/functions/pxToRem";
import rgba from "../theme/functions/rgba";
import card from "../theme/components/card";
import cardMedia from "../theme/components/card/cardMedia";
import cardContent from "../theme/components/card/cardContent";

export default createTheme({
  direction: "rtl",
  breakpoints: { ...breakpoints },
  palette: { ...colors },
  typography: { ...typography },
  boxShadows: { ...boxShadows },
  borders: { ...borders },
  functions: {
    boxShadow,
    hexToRgb,
    linearGradient,
    pxToRem,
    rgba,
  },

  components: {
    MuiCssBaseline: {
      styleOverrides: {
        ...globals,
      },
    },
    MuiCard: { ...card },
    MuiCardMedia: { ...cardMedia },
    MuiCardContent: { ...cardContent },
  },
});
