import React from "react";
import { Button, Modal } from "react-bootstrap";
import { Spinner } from "reactstrap";
import { LoadingDots } from "./Common";
import { styled } from "@material-ui/core";
import { SaveButton } from "./CommonComponents";

function CommonModal({
  heading,
  body,
  show,
  toggle,
  size,
  handleSave,
  saveButtonText = "Save",
  spin = false,
  centered = false,
  style = {},
}) {
  return (
    <div>
      <Modal
        show={show}
        onHide={toggle}
        size={size}
        backdrop="static"
        keyboard={false}
        centered={centered}
        style={style}
      >
        {heading && (
          <Modal.Header closeButton>
            <h6 className="m-0 font-weight-bold text-green">{heading}</h6>
          </Modal.Header>
        )}
        <Modal.Body>{body}</Modal.Body>
        {handleSave && (
          <Modal.Footer style={{ display: "flow" }}>
            <SaveButton
              title={spin ? <LoadingDots /> : saveButtonText}
              disabled={spin}
              type="submit"
              onClick={handleSave}
            />
          </Modal.Footer>
        )}
      </Modal>
    </div>
  );
}

export default CommonModal;
