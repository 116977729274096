import { Route, RouteProps } from "react-router";
import React from "react";
import NewLogin from "../../components/NewLogin/NewLogin";
import Login from "../../components/NewDevelopment/LoginRegister/Login";
import OnboardingPage from "../../components/NewDevelopment/LoginRegister/OnboardingPage";
import { isVodexLoggedin } from "../../components/NewDevelopment/CommonComponents/Common";

export function AccountRoute({ children, ...rest }: RouteProps): JSX.Element {
  return (
    <Route
      {...rest}
      render={() => (isVodexLoggedin() ? <Login /> : <NewLogin />)}
    />
  );
}
