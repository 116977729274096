import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateCurrentPath } from "../../../../store/actions/root.actions";
import {
  addNotificationAction,
  addWhatsappAccount,
  createWhatsappTemplateAction,
  getAllWhatsappAccountsAction,
  getWhatsappAccountByIdAction,
} from "../../Store/Account/Account.action";
import CommonTable from "../../CommonComponents/CommonTable";
import { CommonUsedCard } from "../../CommonComponents/Common";
declare global {
  interface Window {
    FB: any;
  }
}
function Whatsapp() {
  const reducer = useSelector((state: any) => state);
  const dispatch = useDispatch();
  const {
    AccountReducer: {
      userDetails: { email },
      constantData,
      selectedAccount,
    },
  } = reducer;

  const [state, setState] = useState<any>({
    businessData: [],
    selectedId: "",
    headerText: "",
    bodyText: "",
    footerText: "",
    type: "",
    templateName: "",
    selectedData: [],
  });
  const {
    businessData,
    selectedId,
    headerText,
    bodyText,
    footerText,
    type,
    templateName,
    selectedData,
  } = state;
  useEffect(() => {
    dispatch(updateCurrentPath("Integrations/Whatsapp", ""));
    let data = selectedAccount?.waba_business_ids;
    if (data.length > 0) {
      let kl: any = [];
      data.forEach((d) => kl.push(`ids=${d}`));

      const init = async () => {
        let res: any = await dispatch(
          getAllWhatsappAccountsAction(kl.join("&"))
        );

        setState((prev) => ({
          ...prev,
          businessData: [...res.details],
        }));
      };
      init();
    }
  }, []);
  function launchWhatsAppSignup() {
    window.FB.login(
      function (response) {
        if (response.authResponse) {
          dispatch(addWhatsappAccount({ code: response.authResponse.code }));
        } else {
          console.log("User cancelled login or did not fully authorize.");
        }
      },
      {
        config_id: "925057419311402",
        response_type: "code",
        override_default_response_type: true,
        extras: {
          setup: {},
        },
      }
    );
  }

  const getWhatsappDetail = async () => {
    await dispatch(getWhatsappAccountByIdAction("264071120122838"));
  };

  const templateUse = (id) => {
    let found = businessData?.find((d) => d.id === id);
    setState((prev) => ({
      ...prev,
      selectedId: id,
      selectedData: found,
    }));
  };
  const onChange = (e) => {
    e.persist();
    setState((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const createTemplate = async () => {
    if (!selectedId) {
      dispatch(
        addNotificationAction("Please select Whatsapp account", "warning")
      );
      return;
    }
    let obj = {
      header: {
        header_format: "TEXT",
        text: `${headerText}{{1}}`,
        examples: ["Some text"],
      },
      body: {
        text: `${bodyText}{{1}}`,
        examples: [["Some text"]],
      },
      footer: {
        text: footerText,
      },
      category: type,
      language_code: "en_US",
      name: templateName,
    };

    await dispatch(createWhatsappTemplateAction(selectedId, obj));
  };

  console.log(businessData);
  console.log(selectedId);
  console.log(selectedData);
  return (
    <div>
      <button
        onClick={launchWhatsAppSignup}
        style={{
          backgroundColor: "#1877f2",
          border: 0,
          borderRadius: "4px",
          color: "#fff",
          cursor: "pointer",
          fontFamily: "Helvetica, Arial, sans-serif",
          fontSize: "16px",
          fontWeight: "bold",
          height: "40px",
          padding: "0 24px",
        }}
      >
        Login with Facebook
      </button>

      <CommonTable
        body={
          <>
            {businessData.map((bus, idx) => (
              <tr>
                <td>{idx + 1}</td>
                <td>{bus.name}</td>
                <td>{bus?.phone_numbers?.data?.length || 0}</td>
                <td>{bus?.message_templates?.data?.length || 0}</td>
                <td>
                  <button onClick={() => templateUse(bus.id)}>Use</button>
                </td>
              </tr>
            ))}
          </>
        }
        headers={[
          "#",
          "Business Name",
          "Phone Numbers",
          "Message Templates",
          "Use",
        ]}
      />

      <CommonTable
        body={
          <>
            {selectedData?.message_templates?.data?.map((bus, idx) => (
              <tr>
                <td>{idx + 1}</td>
                <td>{bus.name}</td>
                <td>{bus?.category}</td>
                <td>{bus?.status}</td>
                <td>{bus?.language}</td>
              </tr>
            ))}
          </>
        }
        headers={["#", "Template Name", "Category", "Status", "Language"]}
      />

      <CommonUsedCard
        body={
          <>
            <select
              onChange={onChange}
              name="type"
              placeholder="Category"
              className="form-control m-1"
            >
              <option value={""}>Choose Category</option>
              <option value={"MARKETING"}>Marketing</option>
              <option value={"UTILITY"}>Utility</option>
            </select>
            <input
              placeholder="Template Name"
              onChange={onChange}
              className="form-control m-1"
              name="templateName"
            />
            <input
              placeholder="Header Text"
              onChange={onChange}
              className="form-control m-1"
              name="headerText"
            />
            <input
              placeholder="Body Text"
              onChange={onChange}
              className="form-control m-1"
              name="bodyText"
            />
            <input
              placeholder="Footer Text"
              onChange={onChange}
              className="form-control m-1"
              name="footerText"
            />
            <button onClick={createTemplate} className="custom-button-icon m-1">
              Create Template
            </button>
          </>
        }
        title={"Create Template"}
      />
    </div>
  );
}

export default Whatsapp;
